/**
*
* Extra text alignment for bootstrap text
*
*/

/**
*
* https://github.com/twbs/bootstrap/issues/11292
*
*/

//.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
//    text-align: left;
//}
//.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
//    text-align: center;
//}
//.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
//    text-align: right;
//}
//.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
//    text-align: justify;
//}
//
//@media (max-width: $screen-xs-max) {
//    .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
//        text-align: inherit;
//    }
//    .text-left-xs {
//        text-align: left;
//    }
//    .text-center-xs {
//        text-align: center;
//    }
//    .text-right-xs {
//        text-align: right;
//    }
//    .text-justify-xs {
//        text-align: justify;
//    }
//}
//@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
//    .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
//        text-align: inherit;
//    }
//    .text-left-sm {
//        text-align: left;
//    }
//    .text-center-sm {
//        text-align: center;
//    }
//    .text-right-sm {
//        text-align: right;
//    }
//    .text-justify-sm {
//        text-align: justify;
//    }
//}
//@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
//    .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
//        text-align: inherit;
//    }
//    .text-left-md {
//        text-align: left;
//    }
//    .text-center-md {
//        text-align: center;
//    }
//    .text-right-md {
//        text-align: right;
//    }
//    .text-justify-md {
//        text-align: justify;
//    }
//}
//@media (min-width: $screen-lg-min) {
//    .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
//        text-align: inherit;
//    }
//    .text-left-lg {
//        text-align: left;
//    }
//    .text-center-lg {
//        text-align: center;
//    }
//    .text-right-lg {
//        text-align: right;
//    }
//    .text-justify-lg {
//        text-align: justify;
//    }
//}

/**
*
*  https://gist.github.com/ohryan/b9593841c024c033783d
*
*/

.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (min-width: $screen-sm-min) {
    .text-sm-left { text-align: left; }
    .text-sm-right { text-align: right; }
    .text-sm-center { text-align: center; }
    .text-sm-justify { text-align: justify; }
}

@media (min-width: $screen-md-min) {
    .text-md-left { text-align: left; }
    .text-md-right { text-align: right; }
    .text-md-center { text-align: center; }
    .text-md-justify { text-align: justify; }
}

@media (min-width: $screen-lg-min) {
    .text-lg-left { text-align: left; }
    .text-lg-right { text-align: right; }
    .text-lg-center { text-align: center; }
    .text-lg-justify { text-align: justify; }
}