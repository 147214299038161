body {
    //    padding-top: 65px;
}

.admin {
    background-color: red;
}

.main-content {
    margin-top: 80px;
}

.navbar-brand {
    padding-top: 5px;
    font-size: 30px;
    font-weight: bold;
}

.mainwords {
    margin-top: 25vh;
    background: rgba(255, 255, 255, 0.75);
    text-align: center;
    border: 4px solid white;
    .inner {
        z-index: 4;
        img {
            margin-top: 30px;
            max-width: 260px;
        }
        h1 {
            margin-top: 50px;
            color: #555555;
        }
        h2 {
            margin-bottom: 50px;
            color: #555555;
        }
    }
}

/* To have the + on the same line */

.dropdown-menu > li {
    padding: 3px 20px 3px 20px;
}
.dropdown-menu .divider {
    padding: 0;
}
.dropdown-menu > li > a {
    padding: 0;
    display: inline;
}

/**
* Transaction Status
*/
.transaction-status-success {}
.transaction-status-failed {
    color: red;
    font-weight: bold;
}
.transaction-status-pending {}
.transaction-amount-success {}
.transaction-amount-failed {
    text-decoration: line-through;
}
.transaction-amount-pending {}

/**
* Page Tabs
*/
.page-tabs {
}

/**
* Page Action Bar
*/
.page-actions {
    margin-top: 20px;
    margin-bottom: 20px;
}

/**
* Spacers
*/
.navbar-spacer {
    min-height: 75px;
}

.footer-spacer {
    min-height: 50px;
}
