.callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    h4 {
        margin-top: 0;
        margin-bottom: 5px;
    }
    p:last-child {
        margin-bottom: 0;
    }
    code {
        border-radius: 3px;
    }
}
.callout+.callout {
    margin-top: -5px;
}
.callout-default {
    border-left-color: $text-color;
    h4 {
        color: $text-color;
    }
}
.callout-primary {
    border-left-color: $brand-primary;
    h4 {
        color: $brand-primary;
    }
}
.callout-success {
    border-left-color: $brand-success;
    h4 {
        color: $brand-success;
    }
}
.callout-danger {
    border-left-color: $brand-danger;
    h4 {
        color: $brand-danger;
    }
}
.callout-warning {
    border-left-color: $brand-warning;
    h4 {
        color: $brand-warning;
    }
}
.callout-info {
    border-left-color: $brand-info;
    h4 {
        color: $brand-info;
    }
}

